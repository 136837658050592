
#app {
  --primary-color: rgb(38, 123, 189);
  text-align: center;
}

#app .flow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  background-color: var(--primary-color);
  color: white;
  position: sticky;
  padding: 8px 16px;
}

header h1 {
  margin: 0;
}

#app main {
  flex-grow: 1;
  padding: 16px;
}

#app main > * {
  margin-bottom: 16px;
  width: 100%;
  max-width: 600px;
}

button {
  font-size: 150%;
  padding: 8px;
  background: var(--primary-color);
  color: white;
}

button:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(0,0,0,.15);
}