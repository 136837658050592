.show-counts {
  width: 100%;
  max-width: 600px;
  background-color: lightgray;
  display: flex;
  padding: 8px;
  box-sizing: border-box !important;
}

.show-counts .count {
  flex: 1 0;
}

.hidden {
  visibility: hidden;
}